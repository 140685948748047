import React from 'react';

const CaseVideo = () => (
    <section className="case-video">
        <h2><span className="highlight">案例</span>视频</h2>
        {/*<video controls className="video" poster={require('../assets/images/poster.png')}>*/}
        <video className="video" poster={require('../assets/images/poster.png')}>
            <source src="your-video.mp4" type="video/mp4" />
            您的浏览器不支持 video 标签。
        </video>
    </section>
);

export default CaseVideo;