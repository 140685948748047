import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AITools from './components/AITools';
import ProcessSteps from './components/ProcessSteps';
import DetectionRate from './components/DetectionRate';
import Testimonials from './components/Testimonials';
import CaseVideo from './components/CaseVideo';
import Packages from './components/Packages';
import FAQs from './components/FAQs';
import Footer from './components/Footer';

function App() {
  return (
      <div className="app">
          <Navbar/>
          <HeroSection/>
          <AITools/>
          <ProcessSteps/>
          <DetectionRate/>
          <Testimonials/>
          <CaseVideo/>
          <Packages/>
          <FAQs/>
          <Footer/>
      </div>
  );
}

export default App;