import React from 'react';
const packageList = [{
    name: 'Pro',
    description:'为您提供专业的文书制作服务',
    price: '399',
},{
    name: 'Premium',
    description:'为您提供更加优质的文书制作服务',
    price: '899',
},{
    name: 'Premium',
    description:'为您提供至尊全面的文书制作服务',
    price: '1299',
}]
const serviceList = [
    ['GPT-4<span class="highlight">（旗舰模型）</span>',
        '主文书<span class="highlight">1版</span>',
        `文章故事线<span class="highlight">定制1版</span>`,
        '语气、结构定制<span class="highlight">定制</span>',
        '性格特质强调<span class="highlight">强调</span>',
        '自定义精修<span class="highlight">不限次</span>'
    ],
    ['GPT-4<span class="highlight">（旗舰模型）</span>',
        '主文书<span class="highlight">1版</span>',
        `文章故事线<span class="highlight">定制1版</span>`,
        '语气、结构定制<span class="highlight">定制</span>',
        '性格特质强调<span class="highlight">强调</span>',
        '自定义精修<span class="highlight">不限次</span>',
        '专家评估<span class="highlight">润色</span>',
        '大纲比例<span class="highlight">调整</span>',
        '简历<span class="highlight">1份</span>',
        '推荐信<span class="highlight">3份</span>',
        'AI检测率降重<span class="highlight">（0%AI率）</span>',
        'AI检测率<span class="highlight">报告</span>',
    ],
    ['GPT-4<span class="highlight">（旗舰模型）</span>',
        '主文书<span class="highlight">1版</span>',
        `文章故事线<span class="highlight">定制1版</span>`,
        '语气、结构定制<span class="highlight">定制</span>',
        '性格特质强调<span class="highlight">强调</span>',
        '自定义精修<span class="highlight">不限次</span>',
        '专家评估<span class="highlight">润色</span>',
        '大纲比例<span class="highlight">调整</span>',
        '简历<span class="highlight">1份</span>',
        '推荐信<span class="highlight">3份</span>',
        'AI检测率降重<span class="highlight">（0%AI率）</span>',
        'AI检测率<span class="highlight">报告</span>',
        '<span class="highlight">人工</span>降AI编辑润色',
    ],
]
const Packages = () => (
    <section className="packages">
        <h2>精选<span className="highlight">套餐</span></h2>
        <div className="package-list">
            {packageList.map((item, index) => (<div className="package" key={index}>
                <h2>{item.name}</h2>
                <p className="desc">{item.description}</p>
                <div className="price">{item.price}￥起</div>
                <button className="btn">开始使用</button>
                <div className="service">
                    <p className="service-title">您将得到以下服务:</p>
                    {serviceList[index].map((service, i) => (<p className="item" key={i}>
                        <img src={require('../assets/images/icon-success.png')} alt=""/>
                        <span dangerouslySetInnerHTML={{__html: service}}></span>
                    </p>))}
                </div>
            </div>))}
        </div>
    </section>
);

export default Packages;