import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';import 'slick-carousel/slick/slick.css';

import image1 from '../assets/images/slider-1.png';
import image2 from '../assets/images/slider-2.png';
import image3 from '../assets/images/slider-3.png';
import storyline from '../assets/images/storyline.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
};

const HeroSection = () => {
    return (
        <section className="hero-section">
            <h2>留学所需的<span className="highlight">一切文书</span>，<br/>尽在此处。</h2>
            <p>从申请文书到推荐信再到个人简历，我们独家打造的AI模型<br/>7*24小时陪伴你，从背景咨询到生成再到润色，打造您独一无二的留学申请体验。</p>
            <button className="btn">立即免费体验</button>
            <div className="storyline-container">

                <img src={storyline} />
                <Slider className="slider" {...settings}>
                    <div><img src={image1} alt="" /></div>
                    <div><img src={image2} alt="" /></div>
                    <div><img src={image3} alt="" /></div>
                </Slider>
            </div>
        </section>
    );
};

export default HeroSection;