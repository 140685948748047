import React, {useState} from 'react';
const menu = ['素材头脑风暴', '个性化参数', '故事线设计', '板块比重修改', '专家润色系统', '自定义编辑', 'AI降重']
const AITools = () => {
    const [img, setImg] = useState(require('../assets/images/AITools-1.png'));
    const onMouseMove = (index) => {
        setImg(require(`../assets/images/AITools-${index + 1}.png`))
    }
    return (
        <section className="ai-tools">
            <h2><span className="highlight">AI工具</span>助你自在掌握文书全局</h2>
            <div className="content">
                <div className="menu">
                    {menu.map((item, index) => <div className="item"
                        onMouseMove={() => onMouseMove(index)} onClick={() => onMouseMove(index)}>{item}</div>
                    )}
                </div>
                <img src={img} alt=""/>
            </div>
        </section>
    )
}

export default AITools;